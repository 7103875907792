

.spfrecordbox{
    border:1px solid silver;
    padding:20px;
    margin-top:10px;
    overflow-wrap:break-word;
}

.spfcontainer{
    border:0px solid silver;
    padding:20px;
}

.spferror, .spferrorchild{
    padding:20px;
    margin:0px;
    color:#F8696B;
    display:block;
    text-wrap:wrap;
    overflow-wrap:break-word;
    width:100%;
}


.mxrecordbox{
    border:1px solid silver;
    margin-top:20px;
    margin-left:20px;
    padding:20px;
}
.mxrecordheading{
    padding-left:20px;
}

.wordbreakable{
    overflow:visible;
    white-space:normal;
    overflow-wrap:break-word;
    word-break: break-word;
}
