.domainresultcontainer{
    margin-top:50px;
    padding:20px;
    border:1px solid silver;
}

.hostname, .recordtype, .record{
    margin:20px;
}
.hostname{
    
}

.recordtype{

}

.record{

}

.recordrows:nth-child(odd){
    background-color: #eeeded;
}