html,body { height: 100%; width:100%;margin: 0px; padding: 0px; }
#full { background: #0f0; height: 100% }


    .spfresultscontainer{
        text-decoration:none;
        list-style: none !important;
        padding:0;
        border: 0px solid #f2f2f2;
        padding:20px;
		width:100vw;
		
    }
    
    .spfresultscontainer-hidden{
    	visibility:hidden;
    }
    
    .spfresult{
    	margin-top:10px;
    	    	
    }
   
	

  
	
	.grade{
		width:16%;
		display:inline-block;
		padding:10px;
		text-align:center;
		color:#4a4b54;
		height:35px;
		vertical-align:middle;
		opacity:.35;
	}
	.grade_aplus{
		background-color:#63BE7B;
	}
	.grade_a{
		background-color:#A2D07F;
	}
	.grade_b{
		background-color:#E0E383;
	}
	.grade_c{
		background-color:#FDD17F;
	}
	.grade_d{
		background-color:#FA9D75;
	}
	.grade_f{
		background-color:#F8696B;
	}
	
	.grade-selected{
		font-weight:bold;
		height:40px;
		opacity:1.0;
		font-size:large;
	}
	
	
	
	.confighints{
		margin:25px;
	}
	.confighintitem, .confighinticon{
		margin:15px;
		margin-left:45px;
		margin-top:0px;
		margin-bottom:0px;
		text-align:left;
		
		
		
	}
	.confighintstack{
		margin-top:20px;
		
	}
	.confighinterror, .confighintwarning, .confighintinfo{
		height:60px;
		vertical-align: top;
	}
    .confighinterror{
        color:#F8696B;
    }
    .confighintwarning{
        color:#FA9D75;
    }
    .confighintinfo{
        color:#365dca;
    }
	
a, a:focus, a:hover{
    color: #878787;
    text-decoration: none;
}    


.domainSearchBar{
	padding-top:25px;
	position:fixed;
	top:0px;
	background-color: #ffffff;
	border-bottom:1px solid silver;
	width:100%;
	height:125px;
	z-index:10;
}