html,body { height: 100%; width:100%;margin: 0px; padding: 0px; }
#full { background: #0f0; height: 100% }


.spfresultscontainer{
    text-decoration:none;
    list-style: none !important;
    padding:0;
    border: 0px solid #f2f2f2;
    padding:20px;
    width:100vw;
    
}

.spfresultscontainer-hidden{
    visibility:hidden;
}

.spfresult{
    margin-top:10px;
            
}

  
	
a, a:focus, a:hover{
    color: #878787;
    text-decoration: none;
}    


.domainSearchBar{
	padding-top:25px;
	position:fixed;
	top:0px;
	background-color: #ffffff;
	border-bottom:1px solid silver;
	width:100%;
	height:125px;
	z-index:10;
}