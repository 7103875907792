.dmarccontentheading{
    font-weight:bold;
}
.dmarccontent{
    overflow-wrap: break-word;
    margin:20px;
}

.dmarccontentitem{
    margin-left:25px;
}